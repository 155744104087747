<template>
  <div class="rubbishSort">
    <div class="banner">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="notice">
      <div class="notice-c">
        <div class="img"><img src="./img/notice.png" alt="" /></div>
        <div class="txt">
          <div class="txt-l">居民生活垃圾分类“五个一”</div>
          <!-- <div class="txt-r">不合格</div> -->
        </div>
      </div>
      <div class="test">
        <img src="./img/standard.png" alt="" v-if="indexData.actScore == 3" />
        <img
          src="./img/baseStandard.png"
          alt=""
          v-if="indexData.actScore == 2"
        />
        <img
          src="./img/notStandrard.png"
          alt=""
          v-if="indexData.actScore == 1 || -1"
        />
        <div :class="['testTxt', { baseClass: indexData.actScore == 2 }]">
          我的分类等级:<span>{{ getClassTxt }}</span>
        </div>
      </div>
    </div>
    <div class="selectMain">
      <div
        v-for="(item, index) in oList"
        :key="index"
        :class="['oList', item.oClass]"
        @click="toGoal(item)"
        f
      >
        <div class="img"><img :src="item.url" alt="" /></div>
        <div class="txt">{{ item.value }}</div>
      </div>
    </div>
    <div class="dialog" v-if="isDialog">
      <div class="dialog-c">
        <div class="close" @click="toClose">
          <img src="./img/close.png" alt="" />
        </div>
        <div class="title">请业主们先签写承诺书</div>
        <div class="btn">去签写</div>
        <div class="bottom"><img src="./img/back.png" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { rubTopicInfoUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "rubbishSort",
  data() {
    return {
      oList: [
        {
          url: require("./img/promise.png"),
          value: "签订承诺书",
          oClass: "promise",
          code: 1,
        },
        {
          url: require("./img/kind.png"),
          value: "分类小测试",
          oClass: "kind",
          code: 2,
        },
        {
          url: require("./img/knowledge.png"),
          value: "分类知识库",
          oClass: "knowledge",
          code: 3,
        },
        {
          url: require("./img/trash.png"),
          value: "我家垃圾桶",
          oClass: "trash",
          code: 4,
        },
      ],
      isDialog: false,
      indexData: {},
      getClassTxt: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async mounted() {
    // gloabalCount("", 20, 1);
    // await this.getIndexData();
    // this.returnClass();
  },
  methods: {
    toGoal(item) {
      if (item.code == 1) {
        this.$router.push({
          name: "promiseLetter",
        });
      } else if (item.code == 2 || item.code == 3) {
        this.$router.push({
          name: "question",
          query: {
            code: item.code,
            id: this.indexData.id,
          },
        });
      } else {
        this.$router.push({
          name: "rubbishSortList",
          query: {
            id: this.indexData.id,
          },
        });
      }
    },
    returnClass() {
      switch (this.indexData.actScore) {
        case 3:
          this.getClassTxt = "合格";
          break;
        case 2:
          this.getClassTxt = "基本合格";
          break;
        case 1:
          this.getClassTxt = "不合格";
          break;
        default:
          this.getClassTxt = "待评定";
      }
    },
    async getIndexData() {
      let res = await this.$axios.get(rubTopicInfoUrl, {
        params: {
          houseId: this.roomId,
          userId: this.userId,
        },
      });
      if (res.code === 200) {
        this.indexData = res.data;
      } else {
      }
    },
    toClose() {
      this.isDialog = false;
    },
    toCamera() {
      this.$router.push({
        name: "rubbishSortList",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.rubbishSort {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
}
</style>
<style lang="less">
.rubbishSort {
  .banner {
    height: 319px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .notice {
    padding: 18px 30px 42px;
    .notice-c {
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(192, 192, 192, 0.5);
      border-radius: 8px;
      display: flex;
      padding: 8px 14px;
      align-content: center;
      .img {
        width: 38px;
        height: 48px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        display: flex;
        align-content: center;
        font-size: 28px;
        font-weight: 400;
        color: #2f5ea2;
        line-height: 48px;
      }
    }
    .test {
      height: 220px;
      margin: 18px 0 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .testTxt {
        position: absolute;
        top: 80px;
        left: 240px;
        font-size: 40px;
        font-weight: 400;
        color: #ffffff;
        line-height: 56px;
        span {
          font-size: 44px;
          font-weight: 700;
        }
      }
      .baseClass {
        left: 220px;
      }
    }
  }
  .selectMain {
    display: flex;
    padding: 0 30px;
    justify-content: space-between;
    flex-wrap: wrap;
    .oList {
      flex-shrink: 0;
      width: 335px;
      margin-bottom: 16px;
      height: 335px;
      box-shadow: 0px 0px 8px 4px rgba(216, 216, 216, 0.5);
      border-radius: 16px;
      .img {
        width: 108px;
        height: 108px;
        margin: 64px auto 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        color: #ffffff;
        line-height: 56px;
      }
    }
    .promise {
      background: rgba(0, 140, 214, 1);
    }
    .kind {
      background: rgba(248, 204, 92, 1);
    }
    .knowledge {
      background: rgba(229, 0, 18, 1);
    }
    .trash {
      background: rgba(64, 167, 56, 1);
    }
  }
  .camera {
    width: 168px;
    height: 168px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.21);
    border-radius: 84px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 12px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .img {
      width: 76px;
      height: 68px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .dialog {
    background: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .dialog-c {
      width: 646px;
      height: 494px;
      background: #ffffff;
      border-radius: 14px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-top: 108px;
      box-sizing: border-box;
      .close {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 30px;
        right: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        font-size: 36px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 50px;
        text-align: center;
      }
      .btn {
        width: 246px;
        height: 80px;
        background: #2e5da2;
        border-radius: 40px;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        color: #ffffff;
        line-height: 80px;
        margin: 102px auto 40px;
      }
      .bottom {
        width: 574px;
        height: 106px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
